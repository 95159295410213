import Vue from 'vue';
import VueBreadcrumbs from 'vue-2-breadcrumbs';

Vue.use(VueBreadcrumbs, {
  template: `<ol class="navbar-nav" v-if="$breadcrumbs.length">
    <li class="breadcrumb-item"></li>
    <li class="breadcrumb-item" v-for="(crumb, key) in $breadcrumbs" v-if="crumb.meta.breadcrumb" :key="key">
      <router-link :to="{ path: getPath(crumb) }">{{ getBreadcrumb(crumb.meta.breadcrumb) }}</router-link>
    </li>
  </ol>`,
});
