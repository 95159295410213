import Vue from 'vue';
import Vuex from 'vuex';
import auth, { IAuthState } from './auth.module';
import environment, { IEnvironmentState } from './environment.module';
import executor, { IExecutorState } from './executor.module';
import file, { IFileState } from './file.module';
import impersonation, { IImpersonationState } from './impersonation.module';
import project, { IProjectState } from './project.module';
import run, { IRunState } from './run.module';
import schedule, { IScheduleState } from './schedule.module';
import settings, { ISettingsState } from './settings.module';
import toast, { IToastState } from './toast.module';
import user, { IUserState } from './user.module';
import version, { IVersionState } from './version.module';

Vue.use(Vuex);

export interface IRootState {
  auth: IAuthState;
  project: IProjectState;
  environment: IEnvironmentState;
  file: IFileState;
  run: IRunState;
  schedule: IScheduleState;
  toast: IToastState;
  user: IUserState;
  impersonation: IImpersonationState;
  settings: ISettingsState;
  version: IVersionState;
  executor: IExecutorState;
}

export default new Vuex.Store<IRootState>({
  modules: {
    auth,
    project,
    environment,
    file,
    run,
    schedule,
    toast,
    user,
    impersonation,
    settings,
    version,
    executor,
  },
});
