



































import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { KnimeEnvironment } from '../api';

library.add(faSearch);

interface ISearchResponse {
  items: ISearchResult[];
}

interface ISearchResult {
  kind: string;
  name: string;
  nodePitId: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-undef
const nodepitApiBaseUrl = NODEPIT_API_BASE;

@Component
export default class ExtensionSearch extends Vue {
  @Prop() readonly environment!: KnimeEnvironment;

  readonly autoCompleteStyle = {
    defaultInput: 'form-control',
    suggestions: 'list-group',
    suggestItem: 'list-group-item list-group-item-action',
  };

  async searchFeatureInput(value?: string): Promise<ISearchResult[]> {
    if (!value) return [];
    const majorMinorVersion = this.extractMajorMinorVersion(this.environment.knimeVersion);
    const result = await fetch(
      `${nodepitApiBaseUrl}/search?query=${value}&knimeVersion=${majorMinorVersion}&limit=10&kind=iu&kind=node&kind=product`
    );
    const json: ISearchResponse = await result.json();
    return json.items || [];
  }

  async selectFeature(feature?: ISearchResult): Promise<void> {
    if (!feature) return; // no clue why this is happening
    const majorMinorVersion = this.extractMajorMinorVersion(this.environment.knimeVersion);
    const featureData: { iuId: string; repositoryUrl: string }[] = [];
    if (feature.kind === 'iu') {
      const result = await fetch(
        `${nodepitApiBaseUrl}/features/${feature.nodePitId}?knimeVersion=${majorMinorVersion}`
      );
      const json = await result.json();
      featureData.push({ iuId: json.nodePitId, repositoryUrl: json.updateSiteUrl });
    } else if (feature.kind === 'node') {
      const result = await fetch(`${nodepitApiBaseUrl}/nodes/${feature.nodePitId}?knimeVersion=${majorMinorVersion}`);
      const json = await result.json();
      featureData.push({ iuId: json.featureNodePitId, repositoryUrl: json.updateSiteUrl });
    } else if (feature.kind === 'product') {
      const result = await fetch(
        `${nodepitApiBaseUrl}/products/${feature.nodePitId}?knimeVersion=${majorMinorVersion}`
      );
      const json = await result.json();
      featureData.push(...json.features.map((f: any) => ({ iuId: f.nodePitId, repositoryUrl: f.updateSiteUrl })));
    } else {
      return;
    }
    for (const data of featureData) {
      if (!this.environment.ius.find((iu) => iu.identifier === data.iuId)) {
        this.environment.ius.push({ identifier: data.iuId });
      }
      if (!this.environment.repositories.find((repo) => repo.url === data.repositoryUrl)) {
        this.environment.repositories.push({ url: data.repositoryUrl });
      }
    }
  }

  private extractMajorMinorVersion(version: string): string | undefined {
    const regex = /^(\d+\.\d+)/;
    const result = regex.exec(version);
    return result?.[0];
  }

  formatKind(kind: string): string | undefined {
    if (kind === 'iu') {
      return 'Extension';
    } else if (kind === 'node') {
      return 'Node';
    } else if (kind === 'product') {
      return 'Product';
    } else {
      return undefined;
    }
  }
}
