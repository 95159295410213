






import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircleDot } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';

@Component
export default class SaveButton extends Vue {
  @Prop() readonly item: unknown;

  @Prop({ type: String }) readonly form!: string;

  @Prop({ type: Function }) readonly save!: () => void | Promise<void>;

  @Prop({ type: String }) readonly message!: string;

  @Prop({ type: Boolean }) readonly disabled?: boolean;

  private originalItem: unknown = null;

  dirty = false;

  @Watch('item', { deep: true, immediate: true })
  itemChanged(newItem: unknown) {
    if (!this.originalItem && newItem) {
      // initial call - store the first non-null value as original (deep copy!)
      this.originalItem = JSON.parse(JSON.stringify(newItem));
    } else if (JSON.stringify(newItem) !== JSON.stringify(this.originalItem)) {
      this.dirty = true;
    }
  }

  // macOS-style dirty indicator
  get saveIcon() {
    return this.dirty ? faCircleDot : faCircle;
  }

  async delegateBeforeRouteLeave(_to: Route, _from: Route, next: NavigationGuardNext) {
    const formValid = this.formElement.checkValidity();
    if (this.dirty) {
      const saveChanges = await this.$bvModal.msgBoxConfirm(this.message, {
        okVariant: 'dark',
        okTitle: formValid ? 'Save Changes' : 'Continue Editing',
        cancelVariant: 'outline-danger',
        cancelTitle: 'Don’t Save',
      });
      if (saveChanges === true && formValid) {
        await this.save();
      } else if (saveChanges === true && !formValid) {
        return; // cancel
      } else if (saveChanges == null) {
        return; // cancel
      }
    }
    next();
  }

  private get formElement(): HTMLFormElement {
    const formElement = document.getElementById(this.form);
    if (!(formElement instanceof HTMLFormElement)) {
      throw new Error(`${this.form} is not an HTMLFormElement`);
    }
    return formElement;
  }

  /** Call from outside to remove the “dirty” flag (when saving.) */
  setPristine(): void {
    this.originalItem = JSON.parse(JSON.stringify(this.item));
    this.dirty = false;
  }

  setDirty(): void {
    this.dirty = true;
  }
}
