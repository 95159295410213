import { NavigationGuard } from 'vue-router';
import store from '../../store';

const hasUserGuard: NavigationGuard = async (_to, _from, next) => {
  await store.dispatch('settings/loadSettingsCheck');
  const settingsCheck = store.state.settings.settingsCheck;
  if (settingsCheck?.noUser) {
    next('/register');
  } else {
    next();
  }
};

export default hasUserGuard;
