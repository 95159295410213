






import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '../api';

@Component
export default class UserRoleBadge extends Vue {
  @Prop() readonly user: User | undefined;

  get label(): string | undefined {
    if (typeof this.user === 'undefined') return undefined;
    if (typeof this.user.confirmedAt === 'undefined') return 'unconfirmed';
    return this.user.role;
  }
}
