






import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faCircle,
  faDotCircle,
  faMinusCircle,
  faPlayCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';

const mapping: { [k: string]: IconDefinition } = {
  pending: faCircle,
  running: faPlayCircle,
  canceling: faDotCircle,
  passed: faCheckCircle,
  canceled: faMinusCircle,
  failed: faTimesCircle,
};

@Component
export default class RunStatusIcon extends Vue {
  /** The status type. */
  @Prop() readonly status: string | undefined;

  @Prop({ type: Boolean }) noColor: boolean | undefined;

  /** If enabled, the “running” state is animated. */
  @Prop({ type: Boolean }) animated: boolean | undefined;

  get icon(): IconDefinition | undefined {
    return this.status ? mapping[this.status] : undefined;
  }

  get colorClass(): string[] {
    if (this.noColor) {
      return [];
    }
    const classes = [];
    if (this.status) {
      classes.push(this.status);
    }
    if (this.animated && this.status === 'running') {
      classes.push('pulse');
    }
    return classes;
  }
}
