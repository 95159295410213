// nb: loading via @import within a stylesheet does not work;
// https://github.com/webpack-contrib/sass-loader#problems-with-url
import 'typeface-inter';
import Vue from 'vue';
// XXX Deactivate nasty service worker for now
// We enable him oince we need him again.
// import './registerServiceWorker';
import App from './App.vue';
import './directives';
import './plugins';
import router from './router';
import store from './store';
import './stylesheets/index.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
