



















































































































































































































































import { ability } from '@/auth/abilities';
import DurationSelect from '@/components/DurationSelect.vue';
import TagInput from '@/components/TagInput.vue';
import { formatDuration2 } from '@/utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { namespace } from 'vuex-class';
import { Environment, File, Project, Schedule, SettingsCheck } from '../api';
import CronEditor from '../components/CronEditor.vue';
import EnvironmentIcon from '../components/EnvironmentIcon.vue';
import FileUpload from '../components/FileUpload.vue';
import ListEditor from '../components/ListEditor.vue';
import SaveButton from '../components/SaveButton.vue';
import { IListFileParams } from '../store/file.module';
import { IToast } from '../store/toast.module';

const environment = namespace('environment');
const file = namespace('file');
const schedule = namespace('schedule');
const toast = namespace('toast');
const settings = namespace('settings');
const project = namespace('project');

@Component({
  components: { ListEditor, FileUpload, CronEditor, EnvironmentIcon, SaveButton, TagInput, DurationSelect },
})
export default class ScheduleEdit extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };

  @environment.Getter('items')
  environments!: Environment[];

  @environment.Action('loadItems')
  private loadEnvironments!: () => Promise<void>;

  @file.Getter('items')
  files!: File[];

  @file.Action('loadItems')
  private loadFiles!: (params?: IListFileParams) => Promise<void>;

  @schedule.Getter
  item!: Schedule;

  @schedule.Action
  private loadItem!: (id: string) => Promise<void>;

  @schedule.Action
  private loadItemForDuplication!: (id: string) => Promise<void>;

  @schedule.Action
  private createNewItem!: () => Promise<void>;

  @schedule.Action
  private saveItem!: () => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @settings.Action
  private loadSettingsCheck!: () => Promise<SettingsCheck>;

  @settings.Getter
  settingsCheck!: SettingsCheck | null;

  @project.Getter
  currentItem!: Project;

  @Watch('currentItem._id')
  async onCurrentItemChanged() {
    await this.$router.push({ name: 'schedules', params: { projectId: this.currentItem._id } });
  }

  isUploading = false;

  async created(): Promise<void> {
    this.state.loaded = false;
    const id = this.$route.params.id;
    const copy = this.$route.query.copy;
    await this.loadEnvironments();
    await this.loadFiles({ $type: 'upload' });
    if (typeof copy === 'string') {
      await this.loadItemForDuplication(copy);
    } else if (id) {
      await this.loadItem(id);
    } else {
      await this.createNewItem();
    }
    await this.loadSettingsCheck();
    this.state.loaded = true;
  }

  async submit(): Promise<void> {
    await this.save();
    await this.$router.push({ name: 'schedules', params: { projectId: this.currentItem._id } });
  }

  async save(): Promise<void> {
    await this.saveItem();
    this.saveButton.setPristine();
    this.setToast({ message: `Schedule “${this.item.description}” was saved.` });
  }

  uploadStarted(): void {
    this.isUploading = true;
  }

  uploadFinished(): void {
    this.isUploading = false;
  }

  @Watch('item.environment')
  onEnvironmentChanged(environmentId: string): void {
    const environment = this.environments.find((e) => e._id === environmentId);
    if (environment) {
      this.item.type = environment.type;
    }
  }

  filteredFiles(...extensions: string[]): File[] | undefined {
    return this.files.filter((f) => {
      const extension = f.originalName.split('.').pop();
      return extension ? extensions.includes(extension) : false;
    });
  }

  get cannotEdit(): boolean {
    return '_id' in this.item && !!this.item._id && !ability.can('update', this.item);
  }

  cancel(): void {
    this.$router.replace({ name: 'schedules' });
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.saveButton.delegateBeforeRouteLeave(to, from, next);
  }

  private get saveButton(): SaveButton {
    return this.$refs['save-button'] as SaveButton;
  }

  /** For new items, the user can still chose different types, but no longer once it has been created. */
  isDisabled(environment: Environment): boolean {
    if (typeof this.item._id === 'undefined') {
      return false;
    }
    return environment.type !== this.item.type;
  }

  onCancelRunningRunAfterMinutesChanged(value: number | null) {
    this.item.cancelRunningRunAfterMinutes = value;
  }

  onCancelRunningRunAfterMinutesEnabledChanged(value: boolean) {
    this.item.cancelRunningRunAfterMinutesEnabled = value;
  }

  get cancelRunningRunAfterMinutesDefault(): string | undefined {
    if (this.settingsCheck) {
      return this.settingsCheck.cancelRunningRunAfterMinutes
        ? formatDuration2(this.settingsCheck.cancelRunningRunAfterMinutes * 60, { capitalize: true, wordify: true })
        : 'Never';
    } else {
      return undefined;
    }
  }
}
