







































import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';

library.add(faPlus, faTimes, faInfoCircle);

@Component
export default class ListEditor extends Vue {
  @Prop() readonly label: string | undefined;

  @Prop() readonly description: string | undefined;

  @Prop() readonly value!: any[] | undefined;

  /** Factory for creating the properties for a new row, or the value itself (will be cloned). */
  @Prop() readonly createRow?: () => any | any;

  @Prop({ default: () => () => true }) readonly canEditRow!: (row: any) => boolean;

  private readonly keys = new Map<any, string>();

  addRow(index: number): void {
    const value = this.value ?? [];
    const row =
      typeof this.createRow === 'function'
        ? this.createRow()
        : this.createRow == null
        ? null
        : JSON.parse(JSON.stringify(this.createRow));
    value.splice(index + 1, 0, row);
    this.focusFirstInputInRow(index);
    this.$emit('input', value);
  }

  removeRow(index: number): void {
    const value = this.value ? [...this.value] : [];
    value.splice(index, 1);
    this.$emit('input', value);
  }

  get rows(): (any | { placeholder: true })[] {
    return this.value && this.value.length > 0 ? this.value : [{ placeholder: true }];
  }

  /** VUE!!! I DONT HAVE FUCKING KEYS. BUT YOU KEEP INSISTING.
   * WHY DO YOU MAKE MY LIFE SO MISERABLE?!?!? Okay, so I just
   * generate them in the most stupid way I could come up with.
   * But really. This cannot be it? Probably we just need to
   * create these _ids client-side? */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  giveMeThatFuckingKey(obj: any): string {
    let key = this.keys.get(obj);
    if (!key) {
      key = `${Math.random()}`;
      this.keys.set(obj, key);
    }
    return key;
  }

  private focusFirstInputInRow(rowIndex: number): void {
    setTimeout(() => {
      if (!this.value) {
        return;
      }
      const refIdx = this.value.length === 1 ? 0 : rowIndex + 1;
      const rowRefs = this.$refs[`row-${refIdx}`];
      if (Array.isArray(rowRefs)) {
        const rowRef = rowRefs[0];
        if (rowRef instanceof Element) {
          const input = rowRef.querySelector("input[type='text']");
          if (input instanceof HTMLInputElement) {
            input.focus();
          }
        }
      }
    }, 50);
  }
}
