import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { CreateOrUpdateUserParams, User, UserList } from '../api';
import * as api from '../api';

export interface IUserState {
  users: UserList | null;
  user: User | null;
}

const state: IUserState = {
  users: null,
  user: null,
};

const getters: GetterTree<IUserState, IRootState> = {
  users: (state: IUserState) => state.users,
  user: (state: IUserState) => state.user,
};

const actions: ActionTree<IUserState, IRootState> = {
  loadUsers: async (context: ActionContext<IUserState, IRootState>, params?: { q?: string; confirmed?: boolean }) => {
    const users = await api.listUsers(params);
    context.commit('setUsers', users);
  },
  loadUser: async (context: ActionContext<IUserState, IRootState>, id: string) => {
    const user = await api.getUser(id);
    context.commit('setUser', user);
  },
  createNewUser: (context: ActionContext<IUserState, IRootState>) => {
    const user = { kind: 'user', role: 'user' };
    context.commit('setUser', user);
  },
  saveUser: async (context: ActionContext<IUserState, IRootState>) => {
    let user: User;
    if (context.state.user?._id) {
      user = await api.updateUser(context.state.user._id, context.state.user as CreateOrUpdateUserParams);
    } else if (context.state.user) {
      user = await api.createUser(context.state.user as CreateOrUpdateUserParams);
    } else {
      throw new Error('No user in store');
    }
    context.commit('setUser', user);
  },
  deleteUser: async (context: ActionContext<IUserState, IRootState>, id: string) => {
    await api.deleteUser(id);
  },
  confirmUser: async (context: ActionContext<IUserState, IRootState>, id: string) => {
    await api.confirmUser(id);
  },
};

const mutations: MutationTree<IUserState> = {
  setUsers(state: IUserState, users: UserList | null) {
    state.users = users;
  },
  setUser(state: IUserState, user: User | null) {
    state.user = user;
  },
};

const user: Module<IUserState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default user;
