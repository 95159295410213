





















import { Component, Vue } from 'vue-property-decorator';
import * as api from '../api';
import { ResetParams, ResetWithPasswordParams } from '../api';
import PasswordInput from '../components/PasswordInput.vue';
import UserDialog from '../components/UserDialog.vue';

@Component({
  components: { UserDialog, PasswordInput },
})
export default class ResetPassword extends Vue {
  state: { loaded: boolean; validRecoveryLink: boolean; validPassword: boolean; resetError: boolean } = {
    loaded: false,
    validRecoveryLink: false,
    validPassword: false,
    resetError: false,
  };
  data?: ResetWithPasswordParams;

  async created(): Promise<void> {
    this.data = {
      resetId: this.$route.params.resetId,
      token: this.$route.params.token,
      password: '',
    };
    try {
      const { resetId, token }: ResetParams = this.data;
      await api.checkPasswordReset({ resetId, token });
      this.state.validRecoveryLink = true;
    } finally {
      this.state.loaded = true;
    }
  }

  async onReset(): Promise<void> {
    if (!this.passwordValidated || !this.data) {
      return;
    }
    this.state.resetError = false;
    try {
      const resetResult = await api.resetPassword(this.data);
      localStorage.setItem('token', resetResult.token);
      await this.$router.push({ path: '/' });
    } catch (e) {
      this.state.resetError = true;
    }
  }

  passwordValidated(result: boolean): void {
    this.state.validPassword = result;
  }
}
