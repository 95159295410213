























































































































































import DurationSelect from '@/components/DurationSelect.vue';
import { IVersionInfo } from '@/store/version.module';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { Getter, namespace } from 'vuex-class';
import { Settings, SettingsCheck, User } from '../api';
import * as api from '../api';
import SaveButton from '../components/SaveButton.vue';
import { IToast } from '../store/toast.module';

library.add(faGear);

const toast = namespace('toast');
const settings = namespace('settings');
const version = namespace('version');

@Component({ components: { SaveButton, DurationSelect } })
export default class SettingsComponent extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };
  showSmtpUrl = false;

  @settings.Getter
  settings!: Settings | null;

  @settings.Getter
  settingsCheck!: SettingsCheck | null;

  @version.Getter
  nodePitVersionInfo!: IVersionInfo | null;

  @version.Getter
  clientVersion!: string;

  @settings.Action
  private loadSettings!: () => Promise<void>;

  @settings.Action
  private updateSettings!: () => Promise<void>;

  @settings.Action
  private loadSettingsCheck!: () => Promise<void>;

  @version.Action
  private checkNodePitVersion!: () => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @Getter
  me!: User;

  sendingTestEmail = false;

  sendTestEmailResult: api.SendTestEmailResult | null = null;

  async created(): Promise<void> {
    await Promise.all([this.loadSettings(), this.checkNodePitVersion(), this.loadSettingsCheck()]);
    this.state.loaded = true;
  }

  async submit(): Promise<void> {
    await this.updateSettings();
    this.saveButton.setPristine();
    this.setToast({ message: 'Settings successfully updated' });
  }

  get showLicenseInput() {
    return !this.settings?.licenseKey || !this.settings?.licenseExpires || !this.settings?.licenseIssued;
  }

  get licenseInformation(): string | null {
    if (!this.settings) return null;
    // strip the number of users (XXX - not consistent?!)
    const description = this.settings.licenseDescription
      // Fluorochem, 1 user(s)
      ?.replace(/,\s*\d+ user\(s\)/, '')
      // Daniel Esser (1 user(s))
      ?.replace(/\s*\(\d+ user\(s\)\)/, '');
    return `${description} (expires ${this.settings.licenseExpires})`;
  }

  enterLicense() {
    if (!this.settings) return;
    this.settings.licenseDescription = null;
    this.settings.licenseKey = null;
    this.settings.licenseExpires = null;
    this.settings.licenseIssued = null;
  }

  async sendTestEmail() {
    if (!this.settings?.senderEmail || !this.settings.smtpUrl) {
      return;
    }
    try {
      this.sendingTestEmail = true;
      this.sendTestEmailResult = null;
      this.sendTestEmailResult = await api.sendTestEmail({
        smtpUrl: this.settings.smtpUrl,
        senderEmail: this.settings.senderEmail,
      });
      if (this.sendTestEmailResult.success) {
        this.setToast({ message: `Test email sent to ${this.me.email}` });
      }
    } finally {
      this.sendingTestEmail = false;
    }
  }

  get validEmailConfiguration(): boolean {
    return !!this.settings?.senderEmail && !!this.settings?.smtpUrl;
  }

  get timeZones(): string[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ['UTC', ...Intl.supportedValuesOf('timeZone')];
  }

  useCurrentTZ(): void {
    if (!this.settings) return;
    this.settings.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get versionInfo(): string {
    return `Web: ${this.clientVersion}, API: ${this.settings?.version ?? '?'}`;
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.saveButton.delegateBeforeRouteLeave(to, from, next);
  }

  private get saveButton(): SaveButton {
    return this.$refs['save-button'] as SaveButton;
  }
}
