




import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';

const mapping: { [k: string]: IconDefinition } = {
  ready: faCircle,
  executing: faPlayCircle,
  offline: faCircle,
};

@Component
export default class ExecutorStatusIcon extends Vue {
  /** The status type. */
  @Prop() readonly status: string | undefined;

  get icon(): IconDefinition | undefined {
    return this.status ? mapping[this.status] : undefined;
  }
}
