import Vue from 'vue';
import { capitalize } from 'vue-string-filter';

Vue.filter('capitalize', capitalize);

Vue.filter('filesize', (size: number): string => {
  // 1024 is scientifically correct, but most computers show filesize this way
  const divideBy = 1000;
  if (isNaN(size)) size = 0;
  if (size < divideBy) return `${size.toFixed(0)} bytes`;
  size /= divideBy;
  if (size < divideBy) return `${size.toFixed(0)} kB`;
  size /= divideBy;
  return `${size.toFixed(0)} MB`;
});
