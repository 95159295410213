import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { Schedule } from '../api';
import * as api from '../api';

export interface IScheduleState {
  items: Schedule[] | null;
  item: Schedule | null;
}

const state: IScheduleState = {
  items: null,
  item: null,
};

const getters: GetterTree<IScheduleState, IRootState> = {
  items: (state: IScheduleState) => state.items,
  item: (state: IScheduleState) => state.item,
};

const actions: ActionTree<IScheduleState, IRootState> = {
  loadItems: async (context: ActionContext<IScheduleState, IRootState>) => {
    const { schedules } = await api.listSchedules({ project: context.rootState.project.currentItem?._id });
    context.commit('setItems', schedules);
  },
  loadItem: async (context: ActionContext<IScheduleState, IRootState>, id: string) => {
    const item = await api.getSchedule(id);
    context.commit('setItem', item);
  },
  loadItemForDuplication: async (context: ActionContext<IScheduleState, IRootState>, id: string) => {
    const item = await api.getSchedule(id);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _id, description, ...rest } = item;
    const duplicatedItem = {
      description: `${description} (copy)`,
      ...rest,
    };
    context.commit('setItem', duplicatedItem);
  },
  createNewItem: (context: ActionContext<IScheduleState, IRootState>) => {
    const item = {
      environment: '',
      active: true,
      cron: '0 14 * * MON-FRI',
      cleanupRunAfterMinutes: 1440,
      cancelRunningRunAfterMinutes: null,
      cancelRunningRunAfterMinutesEnabled: false,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
      project: context.rootState.project.currentItem?._id!,
    };
    context.commit('setItem', item);
  },
  saveItem: async (context: ActionContext<IScheduleState, IRootState>) => {
    if (!context.state.item) {
      throw new Error('No item in context');
    }
    let item;
    if (isSchedule(context.state.item)) {
      item = await api.updateSchedule(context.state.item._id, context.state.item);
    } else {
      item = await api.createSchedule(context.state.item);
    }
    context.commit('setItem', item);
  },
  deleteItem: async (context: ActionContext<IScheduleState, IRootState>, id: string) => {
    await api.deleteSchedule(id);
    context.dispatch('loadItems');
  },
};

function isSchedule(item: Schedule | null): item is Schedule {
  return !!item && typeof (item as any)._id === 'string';
}

const mutations: MutationTree<IScheduleState> = {
  setItems(state: IScheduleState, items: Schedule[] | null) {
    state.items = items;
  },
  setItem(state: IScheduleState, item: Schedule | null) {
    state.item = item;
  },
};

const schedule: Module<IScheduleState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default schedule;
