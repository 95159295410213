




























import { HttpError } from 'oazapfts';
import { Component, Vue } from 'vue-property-decorator';
import * as api from '../api';
import UserDialog from '../components/UserDialog.vue';

@Component({
  components: { UserDialog },
})
export default class RecoverPassword extends Vue {
  email = '';
  errorMessage: string | null = null;
  recoveryRequestSent = false;

  async onRecover(): Promise<void> {
    this.errorMessage = null;
    try {
      await api.requestPasswordReset({ email: this.email });
      this.recoveryRequestSent = true;
    } catch (e) {
      if (e instanceof HttpError) {
        // TODO see if we can better type and specify
        // the error responses from the TSOA end.
        const parsedData = JSON.parse(e.data);
        this.errorMessage = parsedData.message;
      }
      if (typeof this.errorMessage !== 'string') {
        this.errorMessage = 'Something went wrong. We do not know what. But does it matter?';
      }
    }
  }

  removeError(): void {
    this.errorMessage = null;
  }
}
