import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-undef
const nodepitApiBaseUrl = NODEPIT_API_BASE;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-undef
const clientVersion = VERSION;

export interface IVersionInfo {
  version: string;
  releaseDate: string;
}

export interface IVersionState {
  nodePitVersionInfo: IVersionInfo | null;
  clientVersion: string;
}

const state: IVersionState = {
  nodePitVersionInfo: null,
  clientVersion,
};

const getters: GetterTree<IVersionState, IRootState> = {
  nodePitVersionInfo: (state: IVersionState) => state.nodePitVersionInfo,
  clientVersion: (state: IVersionState) => state.clientVersion,
};

const actions: ActionTree<IVersionState, IRootState> = {
  checkNodePitVersion: async (context: ActionContext<IVersionState, IRootState>) => {
    const runnerInfo = await fetch(`${nodepitApiBaseUrl}/products/runner`);
    const runnerInfoData = await runnerInfo.json();
    // for simplicity, just compare with `VERSION` (client)
    if (clientVersion !== runnerInfoData.version) {
      context.commit('setNodePitVersionInfo', runnerInfoData);
    }
  },
};

const mutations: MutationTree<IVersionState> = {
  setNodePitVersionInfo(state: IVersionState, versionInfo: IVersionInfo | null) {
    state.nodePitVersionInfo = versionInfo;
  },
};

const settings: Module<IVersionState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default settings;
