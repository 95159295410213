import Vue from 'vue';

// add global <style> tag

/** Just to avoid any collisions. */
const random = Math.floor(Math.random() * 100000);
const cellClass = `clickable-cell-${random}`;
const dummyClass = `dummy-${random}`;

const css = `
  td.${cellClass} {
    position: relative;
  }
  td.${cellClass} a.${dummyClass} {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    /* background-color: red;
    opacity: 0.5; */
  }
  td.${cellClass} :not(.${dummyClass}) {
    /* necessary to be placed before the .dummy link */
    position: relative;
    z-index: 2;
  }
`;
const style = document.createElement('style');
style.type = 'text/css';
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);

Vue.directive('table-row-link', function (el, binding, vnode) {
  if (!vnode.context) {
    throw new Error('no vnode.context');
  }

  // https://forum.vuejs.org/t/using-vue-router-in-a-custom-directive/39528/3
  const router = vnode.context.$router;

  for (const child of el.children) {
    if (!(child instanceof HTMLElement)) {
      continue;
    }

    child.classList.add(cellClass);

    const link = document.createElement('a');
    link.className = dummyClass;

    // assign a “normal” href attribute; this is used for
    // “open in new tab” and to show the URL when hovering
    // https://github.com/vuejs/vue-router/issues/409#issuecomment-333074746
    link.href = router.resolve(binding.value).href;

    link.onclick = (e) => {
      if (e.metaKey || e.ctrlKey) {
        return true; // “open in new tab” -- behave like a normal link
      } else {
        // normal “click” navigation -- prevent href and use router.push
        router.push(binding.value);
        return false;
      }
    };

    child.appendChild(link);
  }
});
