






































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as api from '../api';
import { EmailAndPasswordParams, SettingsCheck } from '../api';
import PasswordInput from '../components/PasswordInput.vue';
import UserDialog from '../components/UserDialog.vue';

const settings = namespace('settings');

@Component({
  components: { UserDialog, PasswordInput },
})
export default class Register extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };

  data: EmailAndPasswordParams = { email: '', password: '' };

  error = false;

  validPassword = false;

  noUser?: boolean;

  @settings.Action
  loadSettingsCheck!: () => Promise<SettingsCheck>;

  @settings.Getter
  settingsCheck!: SettingsCheck | null;

  async created(): Promise<void> {
    if (this.settingsCheck == null) {
      await this.loadSettingsCheck();
    }
    this.state.loaded = true;
    this.noUser = this.settingsCheck?.noUser ?? false;
  }

  async onRegister(): Promise<void> {
    this.error = false;
    try {
      const user = await api.register(this.data);
      localStorage.setItem('token', user.token);
      await this.$router.push({ path: '/' });
    } catch (e) {
      this.error = true;
    }
  }

  removeError(): void {
    this.error = false;
  }

  passwordValidated(result: boolean): void {
    this.validPassword = result;
  }
}
