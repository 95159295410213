import { ability } from '@/auth/abilities';
import { NavigationGuard } from 'vue-router';
import store from '../../store';

const isLoggedInGuard: NavigationGuard = async (to, from, next) => {
  // we redirect to `/login` in the `/plugins/axios.ts` interceptor
  await store.dispatch('loadMe');
  if (ability.can('view', 'dashboard')) {
    next(); // user was confirmed
  } else {
    next('/pending'); // not confirmed
  }
};

export default isLoggedInGuard;
