
























import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PasswordValidator from 'password-validator';
import { Component, Prop, Vue } from 'vue-property-decorator';

library.add(faCheck);

@Component
export default class PasswordInput extends Vue {
  private readonly validator = new PasswordValidator()
    .is()
    .min(8)
    .has()
    .letters()
    .has()
    .digits()
    .has()
    .symbols()
    .has()
    .not()
    .spaces();

  /** See https://developer.apple.com/password-rules/; must match the validator rules above */
  readonly passwordrules = 'minlength: 8; required: upper; required: lower; required: digit; required: special';

  /** `null` initially, or when no value was entered. */
  private validationResult: string[] | null = null;

  @Prop() readonly value: string | undefined;

  @Prop({ type: String, default: 'password' }) readonly id!: boolean;

  @Prop({ type: Boolean, default: false }) readonly autofocus!: boolean;

  @Prop() readonly autocomplete: string | undefined;

  onInputPassword(value: string): void {
    this.validationResult = value.length ? (this.validator.validate(value, { list: true }) as string[]) : null;
    const isValidPassword = Array.isArray(this.validationResult) && this.validationResult.length === 0;
    this.$emit('input', value);
    this.$emit('validated', isValidPassword);
  }

  okClass(rule: string): { ok: boolean } {
    const ok = Array.isArray(this.validationResult) && !this.validationResult.includes(rule);
    return { ok };
  }
}
