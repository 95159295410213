











import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

@Component
export default class NavigationItem extends Vue {
  readonly id = `nav-item-${Math.floor(Math.random() * 10000)}`;
  @Prop({ required: true }) readonly collapsed!: boolean;
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly to!: RawLocation;
  @Prop({ required: true }) readonly icon!: string;
  @Prop() readonly disabled?: boolean;
  @Prop() readonly count?: number;
  @Prop() readonly activePattern?: string;

  get showBadge() {
    return typeof this.count === 'number';
  }

  get active() {
    return this.activePattern ? new RegExp(this.activePattern).test(this.$route.path) : undefined;
  }
}
