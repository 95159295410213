












import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UserDialog extends Vue {
  // empty
}
