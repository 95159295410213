






import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateAndTime, formatDateTime } from '../utils';

@Component
export default class DateTime extends Vue {
  @Prop({ type: String }) readonly date: string | undefined;

  @Prop({ type: Boolean }) readonly dateOnly: boolean | undefined;

  /** Enable if the component is integrated within a sentence, e.g. “Run was triggered XXX” -
   * it will change capitalization of the relative dates to lowercase. */
  @Prop({ type: Boolean }) readonly inline: boolean | undefined;

  get parts(): DateAndTime | undefined {
    return formatDateTime(this.date, this.inline);
  }
}
