import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { Executor } from '../api';
import * as api from '../api';

export interface IExecutorState {
  executor: Executor | null;
}

const state: IExecutorState = {
  executor: null,
};

const getters: GetterTree<IExecutorState, IRootState> = {
  executor: (state: IExecutorState) => state.executor,
};

const actions: ActionTree<IExecutorState, IRootState> = {
  loadExecutor: async (context: ActionContext<IExecutorState, IRootState>, id: string) => {
    const item = await api.getExecutor(id);
    context.commit('setExecutor', item);
  },
};

const mutations: MutationTree<IExecutorState> = {
  setExecutor(state: IExecutorState, executor: Executor | null) {
    state.executor = executor;
  },
};

const executor: Module<IExecutorState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default executor;
