import { Action, StringSubject } from '@/api';
import { Ability, AbilityClass, AbilityTuple } from '@casl/ability';

// TODO check about TS customization as described here:
// https://casl.js.org/v4/en/package/casl-vue#type-script-support

export type Subject = StringSubject | { kind: string; [key: string]: any };

export type AppAbility = Ability<AbilityTuple<Action, Subject>>;
// eslint-disable-next-line no-redeclare
export const AppAbility = Ability as AbilityClass<AppAbility>;

/** Initially, this is empty, but we can update it using the
 * `update` function when the user’s abilities have been loaded
 * from the server. */
export const ability = new AppAbility([], {
  // `kind` is set as virtual in the Mongoose models
  // https://casl.js.org/v4/en/guide/subject-type-detection#custom-subject-type-detection
  // https://github.com/stalniy/casl/blob/202d82d40367181768a0fc3a6ba952e973dde8da/packages/casl-ability/src/utils.ts#L40
  detectSubjectType: (subject) => subject.kind,
});
