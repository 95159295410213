



























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IToast } from '../store/toast.module';

const toast = namespace('toast');

@Component
export default class Toast extends Vue {
  @toast.Getter
  toast!: IToast | null;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  get showCountdown(): boolean | number {
    if (!this.toast) {
      return false;
    }
    if (this.toast.type === 'error') {
      return true; // no automatic dismiss
    }
    return 2; // dismiss automatically after 2 seconds
  }

  get dismissable(): boolean {
    return this.toast?.type === 'error';
  }

  onDismissed(): void {
    this.setToast(null);
  }
}
