import { NavigationGuard } from 'vue-router';
import { ability } from '../../auth/abilities';
import store from '../../store';

export const canEnterProjectGuard: NavigationGuard = async (to, from, next) => {
  const project = store.state.project.items?.projects.find((p) => p._id === to.params.projectId);
  if (!project || !ability.can('get', project)) {
    store.state.toast.toast = {
      message: `Project “${to.params.projectId}” does not exist or you do not have permission to enter it -- we took you home.`,
      type: 'error',
    };
    next({ name: 'dashboard' });
  } else {
    const currentProject = store.state.project.currentItem;
    if (currentProject?._id !== project._id) {
      await store.dispatch('project/selectCurrentItemId', project._id);
    }
    next();
  }
};
