














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SearchInput extends Vue {
  @Prop() readonly value!: string;

  @Prop({ default: 'Search' }) readonly placeholder!: string;

  @Prop({ default: 'search' }) readonly icon!: string;

  onUpdate(value: string): void {
    this.$emit('input', value);
  }
}
