














































import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { Action, namespace } from 'vuex-class';
import { User } from '../api';
import SaveButton from '../components/SaveButton.vue';
import { IToast } from '../store/toast.module';

const user = namespace('user');
const toast = namespace('toast');

@Component({ components: { SaveButton } })
export default class UserEdit extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };

  @user.Getter
  user!: User;

  @user.Action
  private loadUser!: (_id: string) => Promise<void>;

  @user.Action
  private createNewUser!: () => Promise<void>;

  @user.Action
  private saveUser!: () => Promise<void>;

  @Action
  private loadMe!: () => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  async created(): Promise<void> {
    this.state.loaded = false;
    const userId = this.$route.params.id;
    if (userId) {
      await this.loadUser(userId);
    } else {
      await this.createNewUser();
    }
    this.state.loaded = true;
  }

  async submit(): Promise<void> {
    await this.save();
    await this.$router.push({ name: 'users' });
  }

  async save(): Promise<void> {
    await this.saveUser();
    this.saveButton.setPristine();
    this.setToast({ message: `User “${this.user.email}” was saved.` });
    // when editing the user him/herself, reload the information
    // (e.g. the gravatar image should change when editing the
    // email address)
    await this.loadMe();
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.saveButton.delegateBeforeRouteLeave(to, from, next);
  }

  private get saveButton(): SaveButton {
    return this.$refs['save-button'] as SaveButton;
  }
}
