




































import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import * as api from '../api';
import { File } from '../api';
import FileExtensionIcon from '../components/FileExtensionIcon.vue';
import { downloadUrl, formatDateTime } from '../utils';

library.add(faTimes, faDownload);

@Component({ components: { FileExtensionIcon } })
export default class FileUpload extends Vue {
  @Prop() private files!: File[];

  @Prop() readonly label: string | undefined;

  @Prop() readonly description: string | undefined;

  /** List of accepted file extension(s); if multiple separate with comma. */
  @Prop() readonly accept: string | undefined;

  @Prop({ type: Boolean }) readonly required: boolean | undefined;

  @Prop() readonly value: File | undefined;

  @Prop() readonly uploadStarted?: () => any;

  @Prop() readonly uploadFinished?: () => any;

  @Prop({ required: true }) readonly project!: string;

  @Ref('file') readonly fileInput!: HTMLInputElement;

  /** Upload progress in percent; `null` means: no upload running. */
  uploadProgress: number | null = null;

  readonly id = `file-upload-${Math.floor(Math.random() * 100000)}`;

  async upload(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.uploadStarted?.();
      try {
        const fetch = api.makeCustomFetch({ onUploadProgress: (p) => (this.uploadProgress = p), showToast: true });
        const uploadResult = await api.uploadFile({ file, project: this.project }, { fetch });
        this.files.push(uploadResult);
        this.$emit('input', uploadResult);
      } finally {
        this.uploadProgress = null;
        this.uploadFinished?.();
        this.fileInput.value = '';
      }
    }
  }

  onDownloadFile(): void {
    if (this.value) {
      downloadUrl(`/api/${this.value.download}`);
    }
  }

  get filteredFiles(): File[] | undefined {
    if (typeof this.accept === 'undefined') {
      return this.files;
    }
    const extensions = this.accept.split(/,\s+/);
    return this.files.filter((f) => {
      const extension = f.originalName.split('.').pop();
      return extension ? extensions.includes(`.${extension}`) : false;
    });
  }

  get mappedValue(): File | null {
    if (!this.value || this.value._id === '') {
      return null;
    }
    return this.value;
  }

  onChangeFile(value: File): void {
    this.$emit('input', value);
  }

  formattedDateTime(value: File): string | undefined {
    const parts = formatDateTime(value.createdAt);
    return parts?.combined;
  }

  get filename(): string | undefined {
    return this.value?.originalName;
  }
}
