



















import { library } from '@fortawesome/fontawesome-svg-core';
import { faMasksTheater } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GlobalEvents } from '../events/global-events';

library.add(faMasksTheater);

const impersonation = namespace('impersonation');

@Component
export default class TopBar extends Vue {
  loading = 0;

  @impersonation.Getter
  impersonatedUserId!: string | null;

  created(): void {
    GlobalEvents.$on('requestStarted', this.onRequestStart);
    GlobalEvents.$on(['requestCompleted', 'requestErrored'], this.onRequestEnd);
  }

  destroyed(): void {
    GlobalEvents.$off('requestStarted', this.onRequestStart);
    GlobalEvents.$off(['requestCompleted', 'requestErrored'], this.onRequestEnd);
  }

  private onRequestStart(): void {
    this.loading++;
  }

  private onRequestEnd(): void {
    // timeout to prevent on/off “flickering”,
    // when a new request follows immediately
    setTimeout(() => {
      this.loading--;
    }, 50);
  }
}
