




import { fileKnwf } from '@/icon-pack';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFile, faFileCode, faFileLines, faFileZipper } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';

const mapping: { [k: string]: IconDefinition } = {
  sh: faFileCode,
  knwf: fileKnwf,
  epf: faFileLines,
  gz: faFileZipper,
  zip: faFileZipper,
};

@Component
export default class FileExtensionIcon extends Vue {
  @Prop() readonly name: string | undefined;

  get icon(): IconDefinition | undefined {
    // https://stackoverflow.com/a/52555531/2277357
    const extension = (this.name?.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
    return mapping[extension] || faFile;
  }
}
