







import { Component, Prop, Vue } from 'vue-property-decorator';
import RunStatusIcon from '../components/RunStatusIcon.vue';

@Component({
  components: { RunStatusIcon },
})
export default class RunStatus extends Vue {
  /** The status type. */
  @Prop() readonly status: string | undefined;

  /** If enabled, the “running” state is animated. */
  @Prop({ type: Boolean }) animated: boolean | undefined;
}
