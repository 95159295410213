














































































































import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faCircleInfo, faRocket } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import * as api from '../api';
import { Executor, ExecutorList } from '../api';
import DateTime from '../components/DateTime.vue';
import EmptyListPlaceholder from '../components/EmptyListPlaceholder.vue';
import ExecutorStatusIcon from '../components/ExecutorStatusIcon.vue';
import SearchInput from '../components/SearchInput.vue';
import { IToast } from '../store/toast.module';

library.add(faRocket, faBan, faCircleInfo);

const toast = namespace('toast');

@Component({
  components: { ExecutorStatusIcon, EmptyListPlaceholder, DateTime, SearchInput },
})
export default class ExecutorListComponent extends Vue {
  private pollInterval: number | undefined;
  executorList: ExecutorList | null = null;
  private q = '';
  cleanupRunning = false;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  async mounted(): Promise<void> {
    await this.load();
    this.pollInterval = setInterval(() => void this.load(), 10000);
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
  }

  onSearchInput(q: string) {
    this.q = q;
    this.load();
  }

  private async load(): Promise<void> {
    this.executorList = await api.listExecutors({ q: this.q });
  }

  async remove(item: Executor): Promise<void> {
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete executor “${item.description}”?`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await api.deleteExecutor(item._id);
      this.load();
      this.setToast({ message: `Executor “${item.description}” was deleted.` });
    }
  }

  async cleanupExecutors(): Promise<void> {
    this.cleanupRunning = true;
    try {
      const cleanupResult = await api.cleanupExecutors();
      this.load();
      this.setToast({ message: `Cleanup removed ${cleanupResult.numExecutorsDeleted} executors.` });
    } finally {
      this.cleanupRunning = false;
    }
  }
}
