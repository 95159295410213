import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';

export interface IToast {
  message: string;
  type?: 'info' | 'error';
}

export interface IToastState {
  toast: IToast | null;
}

const state: IToastState = {
  toast: null,
};

const getters: GetterTree<IToastState, IRootState> = {
  toast: (state: IToastState) => state.toast,
};

const actions: ActionTree<IToastState, IRootState> = {};

const mutations: MutationTree<IToastState> = {
  setToast(state: IToastState, toast: IToast | null) {
    state.toast = toast;
  },
};

const toast: Module<IToastState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default toast;
