


















import { Component, Vue } from 'vue-property-decorator';
import LicenseBanner from '../components/LicenseBanner.vue';
import NavigationBar from '../components/NavigationBar.vue';
import Toast from '../components/Toast.vue';
import TopBar from '../components/TopBar.vue';

@Component<Wrapper>({
  components: {
    TopBar,
    NavigationBar,
    LicenseBanner,
    Toast,
  },
})
export default class Wrapper extends Vue {
  get fullWidth(): boolean {
    // note: this cannot be passed via properties,
    // as we set it from a *child* component in the
    // routing configuration, and these do not
    // propagate “upwards”.
    // https://stackoverflow.com/a/56921729
    return !!this.$route.meta?.fullWidth;
  }
}
