




import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCloudArrowUp, faSquarePollHorizontal } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';

const mapping: { [k: string]: IconDefinition } = {
  upload: faCloudArrowUp,
  artifact: faSquarePollHorizontal,
};

@Component
export default class FileTypeIcon extends Vue {
  @Prop() readonly type: string | undefined;

  get icon(): IconDefinition | undefined {
    return this.type ? mapping[this.type] : undefined;
  }
}
