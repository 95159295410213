



























































































import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight, faBan, faClone, faFolder, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Project, ProjectList } from '../api';
import DateTime from '../components/DateTime.vue';
import EmptyListPlaceholder from '../components/EmptyListPlaceholder.vue';
import ProjectRoleBadge from '../components/ProjectRoleBadge.vue';
import SearchInput from '../components/SearchInput.vue';
import { IToast } from '../store/toast.module';

library.add(faPlus, faBan, faFolder, faClone, faArrowRight, faPen);

const toast = namespace('toast');
const project = namespace('project');

@Component({
  components: { EmptyListPlaceholder, DateTime, SearchInput, ProjectRoleBadge },
})
export default class ProjectListComponent extends Vue {
  private pollInterval: number | undefined;
  private q = '';

  @project.Getter
  items!: ProjectList;

  @project.Action
  loadItems!: (params: { q: string }) => Promise<void>;

  @project.Action
  deleteItem!: (id: string) => Promise<void>;

  @project.Action
  selectCurrentItemId!: (project: string | null) => Promise<void>;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  async onSearchInput(q: string) {
    this.q = q;
    await this.loadItems({ q: this.q });
  }

  async mounted(): Promise<void> {
    await this.loadItems({ q: this.q });
    this.pollInterval = setInterval(() => void this.loadItems({ q: this.q }), 10000);
    this.selectCurrentItemId(null);
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
  }

  async remove(project: Project): Promise<void> {
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete project “${project.description}”?`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await this.deleteItem(project._id);
      await this.loadItems({ q: this.q });
      this.setToast({ message: `Project “${project.description}” was deleted.` });
    }
  }
}
