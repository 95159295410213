


























import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { EmailAndPasswordParams } from '../api';
import UserDialog from '../components/UserDialog.vue';

@Component({
  components: { UserDialog },
})
export default class Login extends Vue {
  data: EmailAndPasswordParams = { email: '', password: '' };
  error = false;

  @Action
  private login!: (emailAndPassword: EmailAndPasswordParams) => Promise<void>;

  async onLogin(): Promise<void> {
    this.error = false;
    // Clean potentially invalid token, b/c sending it with
    // the request would trigger an authentication error
    localStorage.removeItem('token');
    try {
      await this.login(this.data);
    } catch (e) {
      this.error = true;
    }
  }

  removeError(): void {
    this.error = false;
  }
}
