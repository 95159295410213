import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { Project, ProjectList } from '../api';
import * as api from '../api';

export interface IProjectState {
  items: ProjectList | null;
  item: Project | null;
  currentItem: Project | null;
}

const state: IProjectState = {
  items: null,
  item: null,
  currentItem: null,
};

const getters: GetterTree<IProjectState, IRootState> = {
  items: (state: IProjectState) => state.items,
  item: (state: IProjectState) => state.item,
  currentItem: (state: IProjectState) => state.currentItem,
};

const actions: ActionTree<IProjectState, IRootState> = {
  loadItems: async (context: ActionContext<IProjectState, IRootState>, params?: { q?: string }) => {
    const items = await api.listProjects(params);
    context.commit('setItems', items);
  },
  loadItem: async (context: ActionContext<IProjectState, IRootState>, id: string) => {
    const item = await api.getProject(id);
    context.commit('setItem', item);
  },
  createNewItem: (context: ActionContext<IProjectState, IRootState>) => {
    context.commit('setItem', {
      description: generateDescription(),
      members: [{ user: context.rootState.auth.me?._id, role: 'owner' }],
    });
  },
  saveItem: async (context: ActionContext<IProjectState, IRootState>) => {
    if (!context.state.item) {
      throw new Error('No item in context');
    }
    let item;
    if (context.state.item._id) {
      item = await api.updateProject(context.state.item._id, context.state.item);
    } else {
      item = await api.createProject(context.state.item);
      // XXX Reload me to get abilities updated with new project
      await context.dispatch('loadMe', null, { root: true });
    }
    context.commit('setItem', item);
    if (context.state.currentItem?._id === item._id) {
      context.commit('setCurrentItem', item);
    }
    await context.dispatch('loadItems');
  },
  deleteItem: async (context: ActionContext<IProjectState, IRootState>, id: string) => {
    await api.deleteProject(id);
  },
  selectCurrentItemId: async (context: ActionContext<IProjectState, IRootState>, currentItemId: string | null) => {
    const project = context.state.items?.projects.find((p) => p._id === currentItemId);
    context.commit('setCurrentItem', project ?? null);
  },
  reloadCurrentItem: async (context: ActionContext<IProjectState, IRootState>) => {
    const id = context.state.currentItem?._id;
    if (typeof id !== 'undefined') {
      const currentProject = await api.getProject(id);
      context.commit('setCurrentItem', currentProject);
    }
  },
};

function generateDescription(): string {
  const emojis = ['🚀', '🧪', '🤖', '⭐️', '📈'];
  const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];
  return `${randomEmoji} My New Project`;
}

const mutations: MutationTree<IProjectState> = {
  setItems(state: IProjectState, items: ProjectList | null) {
    state.items = items;
  },
  setItem(state: IProjectState, item: Project | null) {
    state.item = item;
  },
  setCurrentItem(state: IProjectState, currentItem: Project | null) {
    state.currentItem = currentItem;
  },
};

const project: Module<IProjectState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default project;
