























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TagInput extends Vue {
  @Prop() readonly value!: string[];

  @Prop({ default: 'Tags' }) readonly label!: string;

  @Prop({ default: 'Enter lower-case tags separated by comma' }) readonly placeholder!: string;

  @Prop() readonly disabled?: boolean;

  onInputTags(tags: string[]): void {
    this.$emit('input', tags);
  }

  tagValidator(tag: string) {
    return tag === tag.toLowerCase();
  }
}
