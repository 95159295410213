




import { formatDuration2 } from '@/utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class DurationSelect extends Vue {
  selected: number | null = -1;

  @Prop({ type: Number, default: null }) readonly value!: number | null;
  @Prop({ type: Array, default: () => [] }) readonly options!: number[];

  @Prop({ type: Boolean, default: null }) readonly enabled!: boolean | null;
  @Prop({ type: Number }) readonly default!: number;

  @Watch('value')
  onValueChanged(value: number) {
    this.selected = value;
  }

  @Watch('enabled')
  onEnabledChanged(enabled: boolean) {
    this.selected = enabled ? this.value : -1;
  }

  created(): void {
    this.selected = this.enabled === null || this.enabled ? this.value : -1;
  }

  get allOptions() {
    const options = [];

    if (this.enabled !== null) {
      const defaultDuration = this.default
        ? `${formatDuration2(this.default * 60, { capitalize: true, wordify: true })}`
        : 'Never';
      options.push({ value: -1, text: `Default (${defaultDuration})` });
    }

    for (const option of this.options) {
      let text;
      if (option === null) {
        text = 'Never';
      } else if (option === 0) {
        text = 'Immediately';
      } else {
        text = formatDuration2(option * 60, { capitalize: true, wordify: true });
      }
      options.push({ value: option, text });
    }

    return options;
  }

  onSelectChanged(value: number) {
    this.$emit('input', value !== -1 ? value : null);
    if (this.enabled !== null) {
      this.$emit('update:enabled', value !== -1);
    }
  }
}
