


















import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { SettingsCheck, User } from '../api';

const settings = namespace('settings');

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

@Component
export default class LicenseBanner extends Vue {
  /** Only show the UI once the data has loaded;
   * needed to wrap this into an object, by just
   * using a plain flag it wouldn’t work -- binding
   * issue? */
  state: { loaded: boolean } = { loaded: false };

  @settings.Getter
  private settingsCheck!: SettingsCheck | null;

  @Getter
  private me!: User | null;

  @settings.Action
  private loadSettingsCheck!: () => Promise<void>;

  async created(): Promise<void> {
    await this.loadSettingsCheck();
    this.state.loaded = true;
  }

  get showBanner() {
    return this.showLicenseInvalidMessage || this.showLicenseExpiredMessage;
  }

  get showLicenseInvalidMessage() {
    return this.settingsCheck && !this.settingsCheck.licenseValid;
  }

  /* Show expiry notification starting 14 days before the actual expiry date */
  get expiresInDays(): number | null {
    const licenseExpires = this.settingsCheck?.licenseExpires;
    if (!licenseExpires) {
      return null;
    }
    const expiresInDays = (new Date(licenseExpires).getTime() + ONE_DAY_IN_MS - Date.now()) / ONE_DAY_IN_MS;
    return Math.floor(expiresInDays);
  }

  get showLicenseExpiredMessage() {
    const expiresInDays = this.expiresInDays;
    return typeof expiresInDays === 'number' && expiresInDays < 14;
  }

  get isAdmin() {
    return this.me?.role === 'admin';
  }
}
