
























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as api from '../api';
import { CronCheckError, CronCheckResult } from '../api';
import DateTime from '../components/DateTime.vue';

interface ICronHint {
  fieldName: string;
  range: { from: number; to: number };
  /** Some fields allow a string-based range (e.g. `MON` for “day of week“) */
  stringRange?: { from: string; to: string };
}

@Component({ components: { DateTime } })
export default class CronEditor extends Vue {
  @Prop() readonly label: string | undefined;

  @Prop() readonly description: string | undefined;

  @Prop() readonly value!: string;

  @Prop({ type: Boolean }) readonly required!: boolean;

  cronCheck: CronCheckResult | CronCheckError | null = null;

  hint: ICronHint | null = null;

  focus = false;

  dummy = '';

  async onInputCron(value: string): Promise<void> {
    this.$emit('input', value);
    await this.checkCron(value);
  }

  private async checkCron(value: string) {
    this.cronCheck = await api.cronCheck({ expression: value });
  }

  updateExplanation(e: KeyboardEvent): void {
    const target = e.target as HTMLInputElement;
    if (typeof target.selectionStart !== 'number') return;
    // determine in which section we are
    const parts = this.value.substring(0, target.selectionStart).split(' ');
    const sectionIdx = parts.length - 1;
    this.hint = this.getHintForSection(sectionIdx);

    const value = target.value;
    this.dummy = value.substring(0, target.selectionStart);
  }

  private getHintForSection(sectionIdx: number): ICronHint | null {
    // TODO we could theoretically enter a second group as well;
    // but does this generally make sense? Probably not. Consider
    // to prevent this entirely.
    switch (sectionIdx) {
      case 0:
        return { fieldName: 'minute', range: { from: 0, to: 59 } };
      case 1:
        return { fieldName: 'hour', range: { from: 0, to: 23 } };
      case 2:
        return { fieldName: 'day of month', range: { from: 1, to: 31 } };
      case 3:
        return { fieldName: 'month', range: { from: 1, to: 12 }, stringRange: { from: 'JAN', to: 'DEC' } };
      case 4:
        return { fieldName: 'day of week', range: { from: 0, to: 6 }, stringRange: { from: 'SUN', to: 'SAT' } };
      default:
        return null;
    }
  }

  isCronCheckError(cronCheck: CronCheckResult | CronCheckError | null): cronCheck is CronCheckError {
    return cronCheck != null && 'error' in cronCheck;
  }

  async created(): Promise<void> {
    this.checkCron(this.value);
  }
}
