






import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Project, User } from '../api';

@Component
export default class ProjectRoleBadge extends Vue {
  @Prop() readonly project: Project | undefined;

  @Getter
  private me!: User | null;

  get label(): string | undefined {
    return this.project?.members.find((m) => m.user === this.me?._id)?.role;
  }
}
