import { Action } from '@/api';
import { ability, Subject } from '@/auth/abilities';
import { NavigationGuard } from 'vue-router';
import store from '../../store';

export function canEnter(action: Action, subject: Subject): NavigationGuard {
  return (to, _from, next) => {
    if (ability.can(action, subject)) {
      next();
    } else {
      store.state.toast.toast = {
        message: `You do not have permission to enter ${to.fullPath} -- we took you home.`,
        type: 'error',
      };
      next('/');
    }
  };
}
