import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { EmailAndPasswordParams, UserWithAbilities } from '../api';
import * as api from '../api';
import { ability } from '../auth/abilities';
import router from '../router';
import store from '../store';

export interface IAuthState {
  me: UserWithAbilities | null;
}

const state: IAuthState = {
  me: null,
};

const getters: GetterTree<IAuthState, IRootState> = {
  me: (state) => state.me,
};

const actions: ActionTree<IAuthState, IRootState> = {
  loadMe: async (context: ActionContext<IAuthState, IRootState>) => {
    const user = await api.getMe();
    if ('abilityRules' in user) {
      ability.update(user.abilityRules);
    }
    context.commit('setMe', user);
  },
  login: async (_context: ActionContext<IAuthState, IRootState>, login: EmailAndPasswordParams) => {
    if (!login.email || !login.password) {
      return;
    }
    // do not show error toasts here, as they would
    // only show up later, once the user actually
    // successfully logged in after another attempt
    const user = await api.login(login, { fetch: api.makeCustomFetch({ showToast: false }) });
    localStorage.setItem('token', user.token);
    await router.push({ path: '/' });
  },
  logout: async (context: ActionContext<IAuthState, IRootState>) => {
    context.commit('clear');
    ability.update([]);
    localStorage.removeItem('token');
    store.commit('impersonation/setImpersonating', null);
    await router.push({ path: '/login' });
  },
};

const mutations: MutationTree<IAuthState> = {
  setMe(state: IAuthState, user: UserWithAbilities) {
    state.me = user;
  },
  clear(state: IAuthState) {
    state.me = null;
  },
};

const auth: Module<IAuthState, IRootState> = {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};

export default auth;
