










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EmptyListPlaceholder extends Vue {
  @Prop() readonly icon: string | undefined;
  @Prop() readonly message: string | undefined;
}
