
































































































































import { ability } from '@/auth/abilities';
import EnvironmentIcon from '@/components/EnvironmentIcon.vue';
import SearchInput from '@/components/SearchInput.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faBroom, faPlus, faPowerOff, faSync } from '@fortawesome/free-solid-svg-icons';
import { StateChanger } from 'vue-infinite-loading';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { capitalize } from 'vue-string-filter';
import { namespace } from 'vuex-class';
import * as api from '../api';
import { Project, QueryRunStatus, Run, RunList } from '../api';
import DateTime from '../components/DateTime.vue';
import EmptyListPlaceholder from '../components/EmptyListPlaceholder.vue';
import RunStatus from '../components/RunStatus.vue';
import RunStatusIcon from '../components/RunStatusIcon.vue';
import { IToast } from '../store/toast.module';

library.add(faPlus, faBan, faSync, faPowerOff, faBroom);

const toast = namespace('toast');
const project = namespace('project');

@Component({
  components: { RunStatus, EmptyListPlaceholder, RunStatusIcon, DateTime, EnvironmentIcon, SearchInput },
})
export default class RunListComponent extends Vue {
  private pollInterval: number | undefined;
  runList: RunList | null = null;
  filter: QueryRunStatus = 'all';
  private readonly limit = 25;
  // https://peachscript.github.io/vue-infinite-loading/guide/use-with-filter-or-tabs.html
  infiniteId = 0;
  cleanupRunning = false;
  private q = '';

  @project.Getter
  currentItem!: Project;

  @toast.Mutation
  private setToast!: (toast: IToast | null) => void;

  @Watch('currentItem._id')
  onCurrentItemChanged() {
    this.refresh();
  }

  async mounted(): Promise<void> {
    // no need to explicitly call load here,
    // since the `infiniteHandler` will do that
    this.pollInterval = setInterval(() => this.refresh(), 10000);
  }

  beforeDestroy(): void {
    clearInterval(this.pollInterval);
  }

  onFilter(filter: QueryRunStatus) {
    this.filter = filter;
    if (this.runList?.runs) {
      this.runList.runs = [];
    }
    this.infiniteId++;
  }

  onSearchInput(q: string) {
    this.q = q;
    if (this.runList?.runs) {
      this.runList.runs = [];
    }
    this.infiniteId++;
  }

  /** Refresh the entire currently shown list
   * (means: load everything which is newer than
   * the last item shown in the list). */
  private async refresh() {
    if (this.currentItem) {
      let after: string | undefined;
      if (this.runList?.runs && this.runList.runs.length) {
        // must refresh/replace the entire list here
        // (note -- subtract 1ms from `after` otherwise we’re losing one entry)
        after = new Date(
          new Date(this.runList.runs[this.runList.runs.length - 1].createdAt).getTime() - 1
        ).toISOString();
      }
      const limit = this.runList?.runs?.length || this.limit;
      this.runList = await this.get({ after, limit });
    }
  }

  async abort(run: Run): Promise<void> {
    const reallyAbort = await this.$bvModal.msgBoxConfirm(`Really abort run “${run.description}”?`, {
      okVariant: 'danger',
      okTitle: 'Abort',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyAbort) {
      await api.cancelRun(run._id);
      await this.refresh();
      this.setToast({ message: `Run “${run.description}” was canceled.` });
    }
  }

  async remove(run: Run): Promise<void> {
    if (!this.runList) {
      return;
    }
    const reallyDelete = await this.$bvModal.msgBoxConfirm(`Really delete run “${run.description}”?`, {
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelVariant: 'outline-dark',
      cancelTitle: 'Cancel',
    });
    if (reallyDelete) {
      await api.deleteRun(run._id);
      this.runList.runs = (this.runList.runs || []).filter((r) => r._id !== run._id);
      this.setToast({ message: `Run “${run.description}” was deleted.` });
    }
  }

  async retry(run: Run): Promise<void> {
    if (!this.runList) {
      return;
    }
    let reallyRetry = true;
    const environmentUpdated = run.environment.updatedAt > run.createdAt;
    const scheduleUpdated = run.schedule.updatedAt > run.createdAt;
    if (environmentUpdated || scheduleUpdated) {
      let slot;
      if (environmentUpdated && scheduleUpdated) {
        slot = 'environment and schedule';
      } else if (environmentUpdated) {
        slot = 'environment';
      } else {
        slot = 'schedule';
      }
      reallyRetry = await this.$bvModal.msgBoxConfirm(
        `The run's ${slot} ${
          environmentUpdated && scheduleUpdated ? 'have' : 'has'
        } been updated since the run was created. Retrying the run will use the updated ${slot} and may produce different results. Really retry run “${
          run.description
        }”?`,
        {
          okVariant: 'dark',
          okTitle: 'Retry',
          cancelVariant: 'outline-danger',
          cancelTitle: 'Cancel',
        }
      );
    }
    if (reallyRetry) {
      const retriedRun = await api.retryRun(run._id);
      this.runList.runs.unshift(retriedRun);
      await this.refresh(); // the new run appears on top of the list
      let message = `Retried running “${run.description}”`;
      if (run.description !== retriedRun.description) {
        message += ` as “${retriedRun.description}”`;
      }
      message += '.';
      this.setToast({ message });
    }
  }

  async infiniteHandler($state: StateChanger): Promise<void> {
    const before = this.runList?.runs.length ? this.runList.runs[this.runList.runs.length - 1].createdAt : undefined;
    const result = await this.get({ before });
    // https://peachscript.github.io/vue-infinite-loading/guide/start-with-hn.html
    if (!this.runList) {
      this.runList = result;
      $state.loaded();
    } else if (result.runs.length) {
      this.runList.runs = this.runList.runs.concat(result.runs);
      $state.loaded();
    } else {
      $state.complete();
    }
  }

  private get(args: { before?: string; after?: string; limit?: number }): Promise<RunList> {
    return api.listRuns({
      limit: this.limit,
      status: this.filter,
      project: this.currentItem._id,
      q: this.q,
      ...args,
    });
  }

  async cleanupRuns(): Promise<void> {
    this.cleanupRunning = true;
    try {
      const cleanupResult = await api.cleanupRuns();
      await this.refresh();
      this.setToast({ message: `Cleanup removed ${cleanupResult.numRunsDeleted} runs.` });
    } finally {
      this.cleanupRunning = false;
    }
  }

  get cannotCleanup(): boolean {
    return !ability.can('cleanup', { kind: 'run', project: this.currentItem._id });
  }

  get filterOptions(): { key: QueryRunStatus; class: string; label: string; count: number }[] {
    const runList = this.runList;
    if (!runList) {
      return [];
    }
    return (['all', 'pending', 'running', 'canceling', 'passed', 'canceled', 'failed'] as const).map((property) => ({
      key: property,
      class: this.filter === property ? 'active' : '',
      label: capitalize(property),
      count: runList[`${property}Count` as keyof RunList] as number,
    }));
  }
}
