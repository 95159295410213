



















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { User } from '../api';
import UserDialog from '../components/UserDialog.vue';

@Component({
  components: { UserDialog },
})
export default class Pending extends Vue {
  @Getter
  me!: User | null;

  async mounted(): Promise<void> {
    if (!this.me) {
      await this.navigateToRoot();
    }
  }

  async navigateToRoot(): Promise<void> {
    await this.$router.push({ path: '/' }).catch(() => {
      /* tell me more! */
    });
  }
}
