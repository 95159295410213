import { NavigationGuard } from 'vue-router';
import store from '../../store';

export const isAdmin: NavigationGuard = async (to, from, next) => {
  if (store.state.auth.me?.role === 'admin') {
    next();
  } else {
    store.state.toast.toast = {
      message: `You do not have permission to enter ${to.fullPath} -- we took you home.`,
      type: 'error',
    };
    next('/');
  }
};
