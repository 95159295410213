




import { environmentKnime, environmentShell } from '@/icon-pack';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';

const mapping: { [k in string]: IconDefinition } = {
  knime: environmentKnime,
  shell: environmentShell,
  // nodejs: environmentNodeJs,
  // python: environmentPython,
};

@Component
export default class EnvironmentIcon extends Vue {
  @Prop() readonly type: string | undefined;

  get icon(): IconDefinition {
    return this.type ? mapping[this.type] : faLayerGroup;
  }

  get readableType(): string | undefined {
    switch (this.type) {
      case 'shell':
        return 'Shell';
      case 'knime':
        return 'KNIME';
      default:
        return this.type;
    }
  }
}
