import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { File, QueryFileType } from '../api';
import * as api from '../api';

export interface IFileState {
  items: File[] | null;
  item: File | null;
}

export interface IListFileParams {
  // TODO: FileType? Set type on API side and re-generate
  $type?: QueryFileType;
}

const state: IFileState = {
  items: null,
  item: null,
};

const getters: GetterTree<IFileState, IRootState> = {
  items: (state: IFileState) => state.items,
  item: (state: IFileState) => state.item,
};

const actions: ActionTree<IFileState, IRootState> = {
  loadItems: async (context: ActionContext<IFileState, IRootState>, params: IListFileParams) => {
    const query = { ...params, project: context.rootState.project.currentItem?._id };
    const { files } = await api.listFiles(query);
    context.commit('setItems', files);
  },
  loadItem: async (context: ActionContext<IFileState, IRootState>, id: string) => {
    const item = await api.getFile(id);
    context.commit('setItem', item);
  },
  deleteItem: async (context: ActionContext<IFileState, IRootState>, id: string) => {
    await api.deleteFile(id);
    context.dispatch('loadItems');
  },
  clearItems: (context: ActionContext<IFileState, IRootState>) => {
    context.commit('setItems', []);
  },
};

const mutations: MutationTree<IFileState> = {
  setItems(state: IFileState, items: File[] | null) {
    state.items = items;
  },
  setItem(state: IFileState, item: File | null) {
    state.item = item;
  },
};

const environment: Module<IFileState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default environment;
