import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';

export const impersonatedUserIdKey = 'impersonatedUserId';

export interface IImpersonationState {
  impersonatedUserId: string | null;
}

const state: IImpersonationState = {
  impersonatedUserId: sessionStorage.getItem(impersonatedUserIdKey),
};

const getters: GetterTree<IImpersonationState, IRootState> = {
  impersonatedUserId: (state) => state.impersonatedUserId,
};

const actions: ActionTree<IImpersonationState, IRootState> = {};

const mutations: MutationTree<IImpersonationState> = {
  setImpersonating(state: IImpersonationState, impersonatedUserId: string | null) {
    state.impersonatedUserId = impersonatedUserId;
    if (impersonatedUserId) {
      sessionStorage.setItem(impersonatedUserIdKey, impersonatedUserId);
    } else {
      sessionStorage.removeItem(impersonatedUserIdKey);
    }
  },
};

const impersonation: Module<IImpersonationState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default impersonation;
