import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IRootState } from '.';
import { Settings, SettingsCheck } from '../api';
import * as api from '../api';

export interface ISettingsState {
  settings: Settings | null;
  settingsCheck: SettingsCheck | null;
}

const state: ISettingsState = {
  settings: null,
  settingsCheck: null,
};

const getters: GetterTree<ISettingsState, IRootState> = {
  settings: (state: ISettingsState) => state.settings,
  settingsCheck: (state: ISettingsState) => state.settingsCheck,
};

const actions: ActionTree<ISettingsState, IRootState> = {
  loadSettings: async (context: ActionContext<ISettingsState, IRootState>) => {
    const settings = await api.getSettings();
    context.commit('setSettings', settings);
  },
  updateSettings: async (context: ActionContext<ISettingsState, IRootState>) => {
    if (!context.state.settings) {
      throw new Error('No settings in context');
    }
    const settings = await api.updateSettings(context.state.settings);
    context.commit('setSettings', settings);
    context.dispatch('loadSettingsCheck');
  },
  loadSettingsCheck: async (context: ActionContext<ISettingsState, IRootState>) => {
    const settingsCheck = await api.checkSettings();
    context.commit('setSettingsCheck', settingsCheck);
  },
};

const mutations: MutationTree<ISettingsState> = {
  setSettings(state: ISettingsState, settings: Settings | null) {
    state.settings = settings;
  },
  setSettingsCheck(state: ISettingsState, settingsCheck: SettingsCheck | null) {
    state.settingsCheck = settingsCheck;
  },
};

const settings: Module<ISettingsState, IRootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default settings;
