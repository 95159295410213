import { GlobalEvents } from '@/events/global-events';
import { impersonatedUserIdKey } from '@/store/impersonation.module';
import exfetch from 'exfetch';
import router from '../router';
import store from '../store';
import * as api from './generated';

api.defaults.baseUrl = '/api';
api.defaults.fetch = makeCustomFetch();

export interface IFetchOptions {
  showToast?: boolean;
  onUploadProgress?: (progress: number) => void;
}

export function makeCustomFetch(opts: IFetchOptions = { showToast: true }): typeof fetch {
  // error hooks
  function errorHandler(response: any) {
    if (!response) {
      return;
    }
    const { message, name, status } = response;
    // if authentication error occurred, redirect to login
    if (status === 401) {
      localStorage.removeItem('token');
      // https://github.com/vuejs/vue-router/issu& es/2872#issuecomment-519073998
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      router.push({ path: '/login' }).catch(() => {});
      return;
    }
    // if enabled, show global error toast
    if (opts.showToast) {
      let fullMessage;
      if (typeof message === 'string' && message.length) {
        fullMessage = message;
      } else if (typeof name === 'string' && name.length) {
        fullMessage = name;
      } else {
        fullMessage = 'Something went wrong';
      }
      if (typeof status === 'number') {
        fullMessage += ` (status ${status})`;
      }
      store.state.toast.toast = { message: fullMessage, type: 'error' };
    }
  }

  function plainHeaders(headers: Headers): { [key: string]: string } {
    const plainHeaders: { [key: string]: string } = {};
    headers.forEach((value, key) => (plainHeaders[key] = value));
    return plainHeaders;
  }

  return async (input: RequestInfo | URL, init: RequestInit = {}): Promise<Response> => {
    // authentication header from local storage
    const headers = new Headers(init.headers);
    const token = localStorage.getItem('token');
    if (typeof token === 'string') {
      headers.append('Authorization', `Bearer ${token}`);
    }
    const impersonatedUserId = sessionStorage.getItem(impersonatedUserIdKey);
    if (typeof impersonatedUserId === 'string') {
      headers.append('NodePit-Impersonated-UserId', impersonatedUserId);
    }
    init.headers = plainHeaders(headers);

    // show/hide loading indicator
    GlobalEvents.$emit('requestStarted', { input, init });
    try {
      let result: Response;
      // only use exfetch if we need the upload progress;
      // this is to work around the following bug:
      // https://github.com/developit/unfetch/issues/145
      if (opts.onUploadProgress) {
        const { request, onEvent } = exfetch(input, init);
        onEvent('upload', (e: any) => {
          if (e.lengthComputable && typeof opts.onUploadProgress === 'function') {
            opts.onUploadProgress((100 * e.loaded) / e.total);
          }
        });
        result = await request();
      } else {
        result = await fetch(input, init);
      }

      if (!result.ok) {
        const json = await result.clone().json();
        errorHandler(json);
      }
      // even when error handler triggered,
      // return the actual result
      GlobalEvents.$emit('requestCompleted', { input, init, result });
      return result;
    } catch (err) {
      // network error (e.g. no API response)
      errorHandler(err);
      GlobalEvents.$emit('requestErrored', { input, init, err });
      throw err;
    }
  };
}

export * from './generated'; // re-export everything
